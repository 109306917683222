"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkEmail = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query checkEmail($email: String!) {\n    shopperPreview(email: $email) {\n      isShopper\n      rewardPoints\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var checkEmail = (0, _client.gql)(_templateObject());
exports.checkEmail = checkEmail;